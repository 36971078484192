import { z } from "zod"

const defaultLinkSchema = z.object({
  obfuscate: z.boolean().optional(),
})

export const sbStorySchema = z.object({
  slug: z.string(),
  default_full_slug: z.string(),
  content: z.object({
    component: z.string(),
  }),
  translated_slugs: z.array(z.object({ lang: z.string(), path: z.string(), name: z.string().nullable() })),
})

export const sbStoryLinkSchema = z
  .object({
    linktype: z.literal("story"),
    cached_url: z.string().optional(),
    story: z
      .object({
        slug: z.string(),
        default_full_slug: z.string().optional(),
        full_slug: z.string().optional(),
        content: z
          .object({
            component: z.string().optional(),
          })
          .optional(),
        cached: z.boolean().optional(),
        translated_slugs: z
          .array(z.object({ lang: z.string(), path: z.string(), name: z.string().nullable() }))
          .optional(),
      })
      .optional()
      .transform((value) => {
        if (!value) {
          return value
        }
        return {
          ...value,
          translated_slugs: value?.translated_slugs ?? [],
        }
      })
      .optional(),
  })
  .merge(defaultLinkSchema)

const sbUrlLinkSchema = z
  .object({
    url: z.string(),
    linktype: z.literal("url"),
    fieldtype: z.literal("multilink"),
    target: z.enum(["_blank", "_self"]).nullish(),
  })
  .merge(defaultLinkSchema)

const shopifyLinkSchema = z
  .object({
    id: z.string().nullable(),
    handle: z.string().nullable(),
  })
  .merge(defaultLinkSchema)

export const sbPluginSfIntegrationLinkSchema = z
  .object({
    data: shopifyLinkSchema.nullable(),
  })
  .merge(defaultLinkSchema)

export const linkSchema = z.union([sbStoryLinkSchema, sbUrlLinkSchema, shopifyLinkSchema])

export const sbLinkLabelSchema = z.array(
  z.object({
    link: z.union([sbPluginSfIntegrationLinkSchema, sbStoryLinkSchema, sbUrlLinkSchema]),
    label: z.string(),
    obfuscate: z.boolean().optional(),
  })
)

export type LinkFromSchema = z.infer<typeof linkSchema>
export type SbLinkLabelFromSchema = z.infer<typeof sbLinkLabelSchema>
export type SbLinkFromSchema = z.infer<typeof sbStoryLinkSchema>
export type ShopifyLinkFromSchema = z.infer<typeof shopifyLinkSchema>
export type SbStoryFromSchema = z.infer<typeof sbStorySchema>
