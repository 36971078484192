"use client"

import { forwardRef, useEffect, useState, type LegacyRef } from "react"
import type { RecipeVariants } from "@vanilla-extract/recipes"
import { clsx } from "clsx"

import type { PropsWithClassName } from "~/@types/generics"
import { setProgressBarTheme, type ProgressBarColorTheme } from "~/components/abstracts/Video/utils/set-progress-theme"
import { useVideo } from "~/components/abstracts/Video/VideoProvider"

import * as css from "../styles.css"

export type VideoVolumeBarProps = PropsWithClassName<
  ProgressBarColorTheme &
    RecipeVariants<typeof css.progressBar> & {
      ariaLabel?: string
    }
>

function VideoVolumeBar(
  { className, backgroundColor, color, direction, ariaLabel }: VideoVolumeBarProps,
  ref?: LegacyRef<HTMLDivElement>
) {
  const { videoRef } = useVideo()
  const [currentVolume, setCurrentVolume] = useState(0)

  const fn = () => {
    if (videoRef.current) {
      setCurrentVolume(videoRef.current.volume)
    } else {
      setCurrentVolume(0)
    }
  }

  useEffect(() => {
    videoRef.current?.addEventListener("volumechange", fn)
    videoRef.current?.addEventListener("loadedmetadata", fn)

    return () => {
      videoRef.current?.removeEventListener("volumechange", fn)
      videoRef.current?.removeEventListener("loadedmetadata", fn)
    }
  }, [])

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (videoRef.current) {
      setCurrentVolume(parseFloat(e.currentTarget.value))
      videoRef.current.volume = parseFloat(e.currentTarget.value)
    }
  }

  return (
    <div
      data-comp="Abstracts/VideoProgressBar"
      ref={ref}
      className={clsx(className, css.progressBar({ direction }))}
      style={setProgressBarTheme({ inner: currentVolume }, { backgroundColor, color })}
    >
      <span className={clsx(css.progressBarInner({ direction }))} />
      <input
        className={clsx(css.progressBarInput)}
        type="range"
        min={0}
        max={1}
        step="any"
        aria-label={ariaLabel}
        value={currentVolume}
        onChange={handleOnChange}
      />
    </div>
  )
}

export default forwardRef<HTMLDivElement, VideoVolumeBarProps>(VideoVolumeBar)
