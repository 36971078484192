import { useEffect } from "react"
import type { useQuery } from "@tanstack/react-query"

import type { InferReturn } from "~/@types/generics"

export function useQueryStatus<Return, Error>(
  queryResponse: InferReturn<typeof useQuery<Return, Error, Return>>,
  options: { onError?(error: Error): void; onSuccess?(data: Return): void }
) {
  useEffect(() => {
    switch (queryResponse.status) {
      case "error":
        options.onError?.(queryResponse.error)
        break
      case "success":
        options.onSuccess?.(queryResponse.data)
        break
      default:
        break
    }
  }, [queryResponse.status])
}
