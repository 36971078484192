import { useQuery, type UseQueryOptions } from "@tanstack/react-query"

import type { AllNullable, UnlikelyProduct } from "@unlikelystudio/commerce-connector"

import {
  fetchLinkedProducts,
  type FetchLinkedProductsParams,
} from "~/components/ui/ProductHeader/components/ProductDetails/components/LinkedProducts/_data/get"
import {
  serializeLinkedProducts,
  type SerializedLinkedProducts,
} from "~/components/ui/ProductHeader/components/ProductDetails/components/LinkedProducts/_data/serializer"

type LinkedProducts = Awaited<ReturnType<typeof fetchLinkedProducts>>

type UseLinkedProductsParams<TData = SerializedLinkedProducts> = AllNullable<Partial<FetchLinkedProductsParams>> & {
  queryOptions?: Pick<UseQueryOptions<LinkedProducts, unknown, TData>, "enabled" | "select">
  // Use to store the query in the cache while waiting for the product to be fetched
  productKey?: UnlikelyProduct["id"]
}

export function useLinkedProducts<TData = SerializedLinkedProducts>({
  productKey,
  product,
  locale,
  queryOptions,
}: UseLinkedProductsParams<TData>) {
  const customEnabled = typeof queryOptions?.enabled === "boolean" ? queryOptions?.enabled : true
  const enabled = Boolean(product?.id && locale && customEnabled)

  return useQuery<LinkedProducts, unknown, TData>(
    [
      "linkedProducts",
      {
        // Replace the productKey with the product id once it's fetched
        id: product?.id ?? productKey,
        locale,
      },
    ],
    // @ts-ignore
    async () => (product?.id && locale ? await fetchLinkedProducts({ product, locale }) : []),
    {
      enabled,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      select: (data) => {
        return data?.products && product ? serializeLinkedProducts(product, data?.products, locale) : []
      },
      ...queryOptions,
    }
  )
}
