import type { ISbStoryParams } from "storyblok-js-client"

import type { Nullish } from "~/@types/generics"
import { getDimension, getLang } from "~/lib/i18n/utils/get-i18n"
import Storyblok from "~/lib/storyblok/client"
import { getStoryPath } from "~/lib/storyblok/utils/get-story-path"
import { logger } from "~/utils/logger"

type StoryOptions = Omit<ISbStoryParams, "language"> & { locale: Nullish<string> }

export async function getSbCollectionTree({ locale, ...options }: StoryOptions) {
  try {
    const path = getStoryPath("globals/collection-tree", {})
    const dimension = getDimension(locale)
    const pathWithDimension = `${dimension}/${path}`

    return await Storyblok.getAll(
      "cdn/stories",
      {
        starts_with: pathWithDimension,
        language: getLang(locale),
        per_page: 100,
        version: "published",
        ...options,
      },
      ""
    )
  } catch (error) {
    logger.warn(error)
    throw new Error(`Failed to fetch story: ${JSON.stringify(error, null, 2)}`)
  }
}
