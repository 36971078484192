import type { UnlikelyCartLineInput, UnlikelyCollection } from "@unlikelystudio/commerce-connector"

import type { Nullish } from "~/@types/generics"
import { PRODUCT_CUSTOM_ATTRIBUTES } from "~/lib/shopify/constants"
import type { TVariant } from "~/components/ui/ProductHeader/_data/types"
import type { CartLineItemPayload } from "~/providers/GTMTrackingProvider/constants"

export type ProductTrackingData = {
  name: Nullish<string>
  id: Nullish<string>
  variantId: Nullish<string>
  variantTitle: Nullish<string>
  collections: Nullish<UnlikelyCollection>[]
  sku: Nullish<string>
  quantity: number
  priceAmount: Nullish<number>
}

type CheckoutItem = {
  variant: Nullish<TVariant>
  quantity?: number
  trackingData: CartLineItemPayload | null
}

export function formatCheckoutItems(
  items: CheckoutItem | CheckoutItem[],
  lineAttributes?: UnlikelyCartLineInput["attributes"]
): UnlikelyCartLineInput[] {
  const processedItems = Array.isArray(items) ? items : [items]

  return processedItems
    .map(({ variant, quantity, trackingData }) => {
      if (!variant || !variant.id) return null
      return {
        merchandiseId: variant.id,
        quantity: quantity ?? 1,
        attributes: [
          {
            key: PRODUCT_CUSTOM_ATTRIBUTES.TRACKING_DATA,
            value: JSON.stringify(trackingData),
          },
          variant.image ? { key: PRODUCT_CUSTOM_ATTRIBUTES.THUMBNAIL, value: JSON.stringify(variant.image) } : null,
          ...(lineAttributes ?? []),
        ].filter(Boolean),
      } satisfies UnlikelyCartLineInput
    })
    .filter(Boolean)
}
