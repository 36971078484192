import { z } from "zod"

import type { Nullish } from "~/@types/generics"
import { getSbCollectionTree } from "~/lib/storyblok/queries/get-sb-collection-tree"
import { sbText } from "~/lib/storyblok/schemas/default/text"
import { sbPluginIntegrationShopifyCollectionSchema } from "~/lib/storyblok/schemas/plugins/integration-shopify-collection"
import countSlugElement from "~/lib/storyblok/utils/count-slug-element"
import { handleZodSafeParse } from "~/lib/zod/utils/handle-zod-safe-parse"
import type { TCollectionTree } from "~/managers/CollectionsTreeManager/_data/serializer"

export const collectionTreeStory = z.object({
  content: z.object({
    collection: sbPluginIntegrationShopifyCollectionSchema(),
    label: sbText(),
    hide_on_pdp: z.coerce.boolean(),
  }),
  slug: z.string(),
  full_slug: z.string(),
})

export type TCollectionTreeElement = {
  slug: string
  fullSlug: string
  label: Nullish<string>
  handle: string
  id: string
}

export function serializeCollectionTreeElement(
  element: NonNullable<TCollectionTree>[0]
): TCollectionTreeElement | null {
  if (!element.content.collection) return null

  return {
    slug: element.slug,
    fullSlug: element.full_slug,
    label: element.content.label,
    handle: element.content.collection.handle,
    id: element.content.collection.id,
  }
}

export async function getCollectionTree(locale: Nullish<string>) {
  const collectionTreeData = await getSbCollectionTree({ locale, resolve_links: "url" })

  const collectionTree = handleZodSafeParse(collectionTreeStory.array(), collectionTreeData)

  if (!collectionTree) return null
  return collectionTree
}

export function getProductCollectionsTree(
  handles: Nullish<string[]>,
  collectionTree: Nullish<TCollectionTree>
): Nullish<TCollectionTreeElement[]> {
  if (!collectionTree) return null

  const tree = collectionTree.map(serializeCollectionTreeElement).filter(Boolean)

  const currentCollection =
    handles
      ?.map((handle) => tree.find((t) => t.handle === handle))
      .filter(Boolean)
      .sort((a, b) => countSlugElement(b.fullSlug) - countSlugElement(a.fullSlug))?.[0] ?? null

  const currentCollectionSlug = currentCollection?.fullSlug ?? null

  const elements =
    currentCollectionSlug
      ?.split("/")
      .map((slug) => tree.find((t) => t.slug === slug))
      .filter(Boolean) ?? null

  return elements
}
