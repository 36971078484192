"use client"

import { createRef, type ComponentProps } from "react"
import clsx from "clsx"

import type { PropsWithClassName } from "~/@types/generics"
import Icon, { type IconProps } from "~/components/abstracts/Icon"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

import * as css from "./styles.css"

type CloseButtonProps = Partial<Pick<IconProps, "theme" | "width">> &
  PropsWithClassName<ComponentProps<"button">> & { withHover?: boolean }

function CloseButton({ className, theme = "black", width = 15, withHover = false, ...rest }: CloseButtonProps) {
  const t = useTranslate()
  const buttonRef = createRef<HTMLButtonElement>()

  return (
    <button ref={buttonRef} className={clsx(className)} aria-label={t("aria_close")} {...rest}>
      <div className={clsx(css.iconContainer({ withHover }))}>
        <Icon name="Close" theme={theme} width={width} />
      </div>
    </button>
  )
}

export default CloseButton
