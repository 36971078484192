"use client"

import clsx from "clsx"

import type { PropsWithClassName } from "~/@types/generics"
import ErrorList from "~/components/ui/ErrorList"
import AbstractInput, { type AbstractInputProps } from "~/components/abstracts/Input"
import { useInputPreset } from "~/components/abstracts/Input/hooks"
import { type InputPresets } from "~/components/abstracts/Input/utils/input-preset"

import { sprinkles, type Sprinkles } from "~/styles/sprinkles.css"

import * as css from "./styles.css"

export type InputProps = PropsWithClassName<
  Partial<{
    inputClassName: string
    inputWrapperClassName?: string
    preset: InputPresets
    sprinklesCss: Sprinkles
    label?: string
    labelClassName?: string
  }> &
    AbstractInputProps
>

function Input({
  className,
  inputClassName,
  errorClassname,
  inputWrapperClassName,
  label,
  labelClassName,
  children,
  preset,
  sprinklesCss,
  forwardRef,
  ...rest
}: InputProps) {
  const inputProps = useInputPreset(preset, rest)

  const AbstractInputComponent = (
    <AbstractInput
      errorClassname={clsx(css.Error, errorClassname)}
      className={clsx(css.Input, sprinkles({ paddingLeft: 12, paddingRight: 12, ...sprinklesCss }), inputClassName)}
      forwardRef={forwardRef}
      {...inputProps}
    />
  )

  return (
    <div className={clsx(css.InputContainer, className)}>
      {label && (
        <label className={labelClassName} htmlFor={inputProps.id}>
          {label}
        </label>
      )}
      {children && (
        <div className={inputWrapperClassName}>
          {AbstractInputComponent}
          {children}
        </div>
      )}
      {!children && AbstractInputComponent}
      <ErrorList name={inputProps.name} />
    </div>
  )
}

export default Input
