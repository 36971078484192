import { z } from "zod"

import { getProcessedLocale } from "~/lib/i18n/utils/get-processed-locale"
import type { SbSchemaOptions } from "~/lib/storyblok/schemas"
import { sbPlugin } from "~/lib/storyblok/schemas/helpers"
import type { SbPluginIntegrationShopifyProduct } from "~/lib/storyblok/schemas/plugins/integration-shopify-product"
import { gidSchema } from "~/lib/zod/utils/gid-schema"
import { defaultLink, type TLink } from "~/components/ui/Link/_data/serializer"
import { getPath } from "~/components/ui/Link/utils/href-resolver"

export function sbPluginIntegrationShopifyCollectionSchema() {
  return sbPlugin("integration-shopify-collection")
    .extend({
      data: z
        .object({
          id: gidSchema,
          handle: z.string(),
        })
        .nullable(),
    })
    .transform(({ data }) => data)
}

export type SbPluginIntegrationShopifyCollection = z.infer<
  ReturnType<typeof sbPluginIntegrationShopifyCollectionSchema>
>

export function sbPluginIntegrationShopifyCollectionToLinkSchema(options: SbSchemaOptions) {
  return sbPluginIntegrationShopifyCollectionSchema().transform<TLink>((data) =>
    transformShopifyCollectionToLink(data, options)
  )
}

export function transformShopifyCollectionToLink(data: SbPluginIntegrationShopifyProduct, options: SbSchemaOptions) {
  if (!data) return defaultLink
  const processedLocale = getProcessedLocale(options.locale)
  return {
    href: getPath(processedLocale, "collections/:slug", { slug: data.handle }),
    rel: defaultLink.rel,
  }
}
